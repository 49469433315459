import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vconsole from "vconsole";
import {
    Cascader,
    DatetimePicker,
    Swipe,
    SwipeItem,
    Overlay,
    Picker,
    Icon,
    Rate,
    Dialog,
    Popup,
    Tab,
    Tabs,
    List,
    DropdownMenu,
    DropdownItem,
    Circle,
    Slider,
    Checkbox,
    ActionSheet,
    Sticky
} from 'vant';
import 'vant/lib/index.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'staging') {
    new Vconsole()
}

const app = createApp(App);

app.use(Swipe);
app.use(SwipeItem);
app.use(Overlay);
app.use(Picker);
app.use(Icon);
app.use(Rate);
app.use(Dialog);
app.use(Checkbox);
app.use(Popup);
app.use(Tab);
app.use(Tabs);
app.use(List);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Slider);
app.use(Circle);
app.use(ActionSheet);
app.use(ElementPlus)
app.use(Cascader);
app.use(DatetimePicker)
app.use(Sticky);

app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('wx-open-launch-weapp')
}
app.use(store).use(router).mount('#app');
