<script>
import tools from "./common/js/tools.js";

export default {
    beforeCreate() {
        if (!tools.weixnWap()) {
            if (!tools.isMobile() && tools.isApp() == 0) {
                let Domain = '';
                if (window.location.pathname.includes('play') || window.location.pathname.includes('live')) {
                    Domain = process.env.VUE_APP_playerDomain
                } else if (window.location.pathname.includes('sierCard')) {
                    Domain = process.env.VUE_APP_studyDomain
                } else {
                    Domain = process.env.VUE_APP_sierDomain
                }
                if(!this.$route.meta.changeJump){
                    return
                }else{
                    window.location.href = Domain + window.location.pathname + window.location.search;
                }

            }
        }

    },
};
</script>
<template>
    <router-view/>

</template>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
