import {createStore} from 'vuex'

export default createStore({
    state: {
        loginFlag:false,
        tcPlayer:'',
        userInfo:{}
    },
    getters: {},
    mutations: {
        setLoginFlag(state,data){
            state.loginFlag = data;
        },
        setUserInfo(state,data){
            state.userInfo = data;
        },
        setTcPlayer(state,data){
            state.tcPlayer = data;
        },

    },
    actions: {},
    modules: {}
})
