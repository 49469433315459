import {createRouter, createWebHistory, Router} from 'vue-router';


const router: Router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            redirect: ''
        },
        {
            path: "/question",
            redirect: '/question/detail',
            component: () => import('@/views/index.vue'),
            children: [{
                path: '/question/detail',
                name: 'detail',
                meta: {title: '作业'},
                component: () => import('@/views/question/detail/index.vue'),
            }, {
                path: '/question/analysis',
                name: 'analysis',
                meta: {title: '作业解析'},
                component: () => import('@/views/question/analysis/index.vue'),
            }]
        },
        {
            path: '',
            redirect: '',
            component: () => import('@/views/index.vue'),
            children: [{
                path: '',
                name: 'homePage',
                meta: {title: '首页'},
                component: () => import('@/views/home/index.vue'),
            }, {
                path: '/scancode',
                name: 'scancode',
                meta: {title: '扫码学习'},
                component: () => import('@/views/scancode/index.vue'),

            }, {
                path: '/videoDetail',
                name: 'videoDetail',
                meta: {title: '视频播放'},
                component: () => import('@/views/videoDetail/index.vue'),
            }, {
                path: '/noSecret',
                redirect: '',
                meta: {title: '视频播放'},
                component: () => import('@/views/index.vue'),
                children: [{
                    path: 'live',
                    name: 'noSecretLive',
                    meta: {title: '免密直播'},
                    component: () => import('@/views/play/noSecret/live/index.vue'),
                }, {
                    path: 'player',
                    name: 'noSecretPlayer',
                    meta: {title: '免密点播'},
                    component: () => import('@/views/play/noSecret/player/index.vue'),
                }]
            }, {
                path: '/goWechat',
                name: 'goWechat',
                meta: {title: '训练营小程序'},
                component: () => import('@/views/goWechat/index.vue'),
            }, {
                path: '/sierWechat',
                name: 'sierWechat',
                meta: {title: '小程序'},
                component: () => import('@/views/sierWechat/index.vue'),
            }, {
                path: '/live',
                name: 'live',
                meta: {title: '视频直播'},
                component: () => import('@/views/play/live/index.vue'),
            }, {
                path: '/player',
                name: 'player',
                meta: {title: '视频播放'},
                component: () => import('@/views/play/player/index.vue'),
            }, {
                path: '/open',
                redirect: 'open/play',
                meta: {title: '公开课'},
                component: () => import('@/views/index.vue'),
                children: [
                    {
                        path: 'play',
                        name: 'openPlay',
                        meta: {title: '公开课-录播'},
                        component: () => import('@/views/open/play/index.vue'),
                    },
                    {
                        path: 'live',
                        name: 'openLive',
                        meta: {title: '公开课-直播'},
                        component: () => import('@/views/open/live/index.vue'),
                    },
                    {
                        path: 'lecture',
                        name: 'openLecture',
                        meta: {title: '公开课-讲义列表'},
                        component: () => import('@/views/open/lecture/index.vue'),
                    },
                    {
                        path: 'pdfView',
                        name: 'pdfView',
                        meta: {title: '公开课-讲义'},
                        component: () => import('@/views/open/pdfView/index.vue'),
                    },
                ]
            }, {
                path: '/modelTest',
                redirect: {name: 'modelTest'},
                meta: {title: '模考'},
                component: () => import('@/views/index.vue'),
                children:[
                    {
                        path: 'scanPage',
                        name: 'modelTest',
                        meta: {title: '模考'},
                        component: () => import('@/views/modelTest/scanPage/index.vue'),
                    }, {
                        path: 'bookPage',
                        name: 'modelTestBook',
                        meta: {title: '模考'},
                        component: () => import('@/views/modelTest/bookPage/index.vue'),
                    },
                ]
            }, {
                path: '/bookEquity',
                name: 'bookEquity',
                meta: {title: '图书权益激活'},
                component: () => import('@/views/bookEquity/index.vue'),
            }, {
                path: '/sierCard',
                redirect: {name: 'sierCardList'},
                meta: {title: '斯考卡片'},
                component: () => import('@/views/index.vue'),
                children: [{
                    path: 'list',
                    name: 'sierCardList',
                    meta: {title: '斯考卡片'},
                    component: () => import('@/views/sierCard/list/index.vue'),
                }, {
                    path: 'detail',
                    name: 'sierCardDetail',
                    meta: {title: '斯考卡片包'},
                    component: () => import('@/views/sierCard/detail/index.vue'),
                }, {
                    path: 'favList',
                    name: 'sierCardFavList',
                    meta: {title: '斯考卡片-收藏'},
                    component: () => import('@/views/sierCard/favList/index.vue'),
                }, {
                    path: 'practiceCard',
                    name: 'practiceCard',
                    meta: {title: '斯考卡片-练习'},
                    component: () => import('@/views/sierCard/practiceCard/index.vue'),
                }, {
                    path: 'study',
                    name: 'sierCardStudy',
                    meta: {title: '斯考卡片-练习'},
                    component: () => import('@/views/sierCard/study/index.vue'),
                }]
            },
                {
                    path: '/table',
                    redirect: 'table/FMCTable',
                    meta: {title: '系数表'},
                    component: () => import('@/views/index.vue'),
                    children: [
                        {
                            path: 'FMCTable',
                            name: 'FMCTable',
                            meta: {title: '财管系数表',changeJump:false,},
                            component: () => import('@/views/table/FMCTable/index.vue'),
                        },
                        {
                            path: 'TRTable',
                            name: 'TRTable',
                            meta: {title: '税率表',changeJump:false},
                            component: () => import('@/views/table/TRTable/index.vue'),
                        },
                    ]
                },
                {
                    path: '/leave',
                    name: 'leave',
                    meta: {title: '请假'},
                    component: () => import('@/views/leave/index.vue'),
                }
            ]
        }
    ]
});
router.beforeEach(async (to?: any, from?: any, next?: any) => {
    // set page title
    document.title = `斯尔教育-${to.meta.title}`
    next()
})
router.afterEach((to?: any, from?: any) => {
    if (!window.sessionStorage.firstUrl) {
        window.sessionStorage.firstUrl = window.location.href
    }
})

export default router;
